import {useEffect, useState} from 'react'
import {useQuery} from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import TimeAgo from 'react-timeago'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import ptBRTimeStrings from 'react-timeago/lib/language-strings/pt-br'

import DetailMenuItemBadge from '../../../modules/view/MenuItemBadge'
import {useCardFormModal, useCardFormModalDispatch} from '../core/CardFormModalContext'
import {getCardById} from '../core/_requests'

import {CardsForm} from './CardsForm'
import {useNavigate} from 'react-router-dom'
import {CARD_TYPES} from '../core/_models'
import AnnotationComponent from '../../../../_metronic/helpers/annotation-component/Index'
import AttachmentComponent from '../../../../_metronic/helpers/attachament-component/Index'
import {CUSTOM_FORM_TYPES} from '../../settings/custom-forms/core/_models'
import {getCustomForms, getCardCustomForm} from '../../settings/custom-forms/core/_requests'
import {ListLoading} from '../../../modules/view/ListLoading'
import TaskComponent from '../../../../_metronic/helpers/task-component/Index'

import {useFunnelDispatch} from '../../funnel/core/FunnelContext'

import CardDropDownActions from '../components/actions/CardDropDownActions'

import {useAuth} from '../../../modules/auth'

import {useQueryClient} from 'react-query'
import {useQueryResponse} from '../../../modules/view/QueryResponseProvider'
import {QUERIES} from '../../../../_metronic/helpers'

const formatter = buildFormatter(ptBRTimeStrings)

const HistoryTabDetail = ({
  item,
  activeItem,
  card,
}: {
  item: string
  activeItem: string
  card: any
}) => {
  if (item != activeItem) return null

  const handleOrderData = (historyData: any) => {
    return historyData.sort((a: any, b: any) => {
      const dateA = new Date(a.createdAt)
      const dateB = new Date(b.createdAt)

      // Para ordem decrescente (do mais recente ao mais antigo)
      return dateB.getTime() - dateA.getTime()
    })
  }

  return (
    <>
      <h3 style={{marginTop: '10px', marginBottom: '10px'}}>Atividades</h3>
      {handleOrderData(card.cardHistory)?.map((op: any, index: number) => (
        <div key={'rowform' + index}>
          <div className='d-flex align-items-center mb-5'>
            <div>
              <i
                className='fa-solid fa-circle-exclamation'
                style={{color: 'var(--wl-second)', fontSize: '2.5rem'}}
              ></i>
            </div>
            <div style={{marginLeft: '1rem', lineHeight: '16px'}}>
              <div style={{fontWeight: '700'}}>{op.title}</div>

              <div>
                {op.createdAt && (
                  <TimeAgo live={false} date={new Date(op.createdAt)} formatter={formatter} />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
const DetailMenu = ({card}: {card: any}) => {
  const [item, setItem] = useState<string>('0')

  return (
    <div className='d-flex flex-column'>
      <div className='mb-5'>
        <DetailMenuItemBadge item={'0'} activeItem={item} text='Atividades' onSelect={setItem} />
        <DetailMenuItemBadge item={'1'} activeItem={item} text='Anotações' onSelect={setItem} />
        <DetailMenuItemBadge item={'2'} activeItem={item} text='Tarefas' onSelect={setItem} />
        <DetailMenuItemBadge item={'3'} activeItem={item} text='Anexos' onSelect={setItem} />
      </div>
      <div className=''>
        <HistoryTabDetail item={'0'} activeItem={item} card={card} />
        {item == '1' ? <AnnotationComponent entityData={card} entity='card' /> : null}
        {item == '2' ? <TaskComponent entityData={card} entity='card' /> : null}
        {item == '3' ? <AttachmentComponent entityData={card} entity='card' /> : null}
      </div>
    </div>
  )
}

const CardDetailModal = () => {
  const cardFormModal = useCardFormModal()
  const cardFormModalDispatch = useCardFormModalDispatch()
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  const queryClient = useQueryClient()
  const {query, refetch} = useQueryResponse()

  const funnelDispatch = useFunnelDispatch()

  const handleClose = () => {
    cardFormModalDispatch({
      type: 'close-modal',
    })

    if (
      window?.location?.href?.includes('opportunities') ||
      window?.location?.href?.includes('tickets')
    ) {
      navigate(
        `${cardFormModal.cardType == CARD_TYPES.OPPORTUNITY.id ? '/opportunities' : '/tickets'}`
      )
    }
    if (window?.location?.href?.includes('funnel')) {
      navigate(`/funnel`)
    }
  }

  const loadTitle = (card: any) => {
    switch (card.type) {
      case CARD_TYPES.OPPORTUNITY.id:
        return 'Oportunidade'
      case CARD_TYPES.TICKET.id:
        return 'Tícket'
    }

    return ''
  }

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['cards', 'detail', cardFormModal.cardId],
    queryFn: () => {
      return getCardById(cardFormModal.cardId, 'include_history=true&include_createdBy=true')
    },
    enabled:
      cardFormModal.open && cardFormModal.formAction == 'detail' && cardFormModal.cardId
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: complementCustomFormsIsLoading,
    isError: complementCustomFormsIsError,
    data: complementCustomForms,
    error: complementCustomFormsError,
  } = useQuery({
    queryKey: ['cards', 'detail', 'complement-custom-forms', cardFormModal.cardId],
    queryFn: async () => {
      if (!data?.funnelId) return null
      let auxData = await getCustomForms(
        `entity=card&type=${CUSTOM_FORM_TYPES.COMPLEMENT.id}&funnelId=${data.funnelId}`
      ) //Complemento

      return auxData.data
    },
    enabled:
      cardFormModal.open && cardFormModal.formAction == 'detail' && cardFormModal.cardId && data?.id
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: initialCustomFormIsLoading,
    isError: initialCustomFormIsError,
    data: initialCustomForm,
    error: initialCustomFormError,
  } = useQuery({
    queryKey: ['cards', 'detail', 'initial-custom-forms', cardFormModal.cardId],
    queryFn: () => {
      if (!data?.funnelId) return null
      return getCardCustomForm('card', data.funnelId, CUSTOM_FORM_TYPES.INITIAL.id)
    },
    enabled:
      cardFormModal.open && cardFormModal.formAction == 'detail' && cardFormModal.cardId && data?.id
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (cardFormModal.open && cardFormModal.formAction == 'detail') {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }
  }, [cardFormModal.open])

  const onExecAction = (action: string, data: any) => {
    handleClose()

    if (queryClient && !funnelDispatch) {
      //Se estiver na tela de listagem de cards. Para atualisar a listagem de cards após a exclusão
      switch (action) {
        case 'delete-card':
          queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
          refetch()
          break
      }
    }

    if (funnelDispatch) {
      //Se estiver dentro da página do funil
      switch (action) {
        case 'reopen-card':
          funnelDispatch({
            type: 'update-step-card',
            data: data,
          })
          break
        case 'delete-card':
          funnelDispatch({
            type: 'delete-step-card',
            data: data,
          })
          break
        case 'card-mark-unsolved':
          funnelDispatch({
            type: 'update-step-card',
            data: data,
          })
          break
        case 'card-mark-solved':
          funnelDispatch({
            type: 'update-step-card',
            data: data,
          })
          break
        case 'card-mark-lost':
          funnelDispatch({
            type: 'update-step-card',
            data: data,
          })
          break
        case 'card-mark-win':
          funnelDispatch({
            type: 'update-step-card',
            data: data,
          })
          break
      }
    }
  }

  if (!cardFormModal.open) return null

  if (isError) return null

  return (
    <>
      <Modal
        size='xl'
        show={cardFormModal.open && cardFormModal.formAction == 'detail'}
        onHide={handleClose}
        centered
        scrollable
        contentClassName={'modal-entity-detail'}
      >
        <Modal.Header closeButton>
          <h1 style={{fontWeight: '700', color: '#606161'}}>
            {data ? loadTitle(data) : ''} #{data?.id}
          </h1>
        </Modal.Header>
        <Modal.Body>
          {isLoading || complementCustomFormsIsLoading || initialCustomFormIsLoading || !data ? (
            <ListLoading />
          ) : (
            <Row>
              <Col style={{borderRight: '1px #e1e3ea solid', paddingRight: '1.5rem'}}>
                <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                  <li className='nav-item'>
                    <a
                      className='nav-link active'
                      data-bs-toggle='tab'
                      href='#card_tab_pane_detail'
                    >
                      Detalhes{' '}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#card_tab_pane_contact'>
                      Contato
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#card_tab_pane_company'>
                      Empresa
                    </a>
                  </li>
                  {complementCustomForms?.map((form: any, index: number) => {
                    return (
                      <li className='nav-item'>
                        <a
                          className='nav-link'
                          data-bs-toggle='tab'
                          href={'#card_tab_pane_complement' + form.id}
                        >
                          {form.name}
                        </a>
                      </li>
                    )
                  })}
                </ul>

                <div className='tab-content'>
                  <div
                    className='tab-pane fade show active overflow-hidden'
                    id='card_tab_pane_detail'
                    role='tabpanel'
                  >
                    <div className='entity-relation-card'>
                      {data.contact && (
                        <>
                          <div className='d-flex justify-content-between'>
                            <h5>{data.contact?.name}</h5>
                            <i
                              className='fa-solid fa-arrow-up-right-from-square'
                              style={{
                                fontSize: '1.5rem',
                                cursor: 'pointer',
                                color: 'var(--wl-btn-background-primary-collor)',
                              }}
                              onClick={() => {
                                navigate(`/contacts/details/${data.contact.id}`)
                              }}
                            ></i>
                          </div>
                        </>
                      )}

                      {data.company && (
                        <div className='d-flex justify-content-between'>
                          <h5>{data.company?.name}</h5>
                          <i
                            className='fa-solid fa-arrow-up-right-from-square'
                            style={{
                              fontSize: '1.5rem',
                              cursor: 'pointer',
                              color: 'var(--wl-btn-background-primary-collor)',
                            }}
                            onClick={() => {
                              navigate(`/companies/details/${data.company.id}`)
                            }}
                          ></i>
                        </div>
                      )}

                      <hr></hr>
                      <div className='d-flex justify-content-between'>
                        <div>
                          {data.createdAt
                            ? `Card Criado em ${new Date(data.createdAt).toLocaleString()} ${
                                data.createdBy?.name ? ' - Por ' + data.createdBy?.name : ''
                              }`
                            : ''}
                        </div>
                        <div>
                          <button
                            disabled={false}
                            type='button'
                            className='btn btn-sm fw-bold btn-wl-custom-primary-collor'
                            style={{}}
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='right-start'
                          >
                            Ações
                          </button>
                          <CardDropDownActions card={data} onExecAction={onExecAction} />
                        </div>
                      </div>
                    </div>
                    <br />
                    <CardsForm
                      customFormId={
                        initialCustomForm?.fields?.length > 0 ? initialCustomForm?.id : undefined
                      }
                      isLoading={false}
                      card={data}
                      type={CUSTOM_FORM_TYPES.INITIAL.id}
                      cardtype={cardFormModal.cardType}
                      funnelId={cardFormModal.funnelId ? cardFormModal.funnelId : data.funnel?.id}
                    />{' '}
                  </div>

                  <div className='tab-pane fade show' id='card_tab_pane_contact' role='tabpanel'>
                    {data.contact && (
                      <>
                        <div className='entity-relation-card'>
                          <div className='d-flex justify-content-between'>
                            <h5>{data.contact?.name}</h5>
                            <i
                              className='fa-solid fa-arrow-up-right-from-square'
                              style={{
                                fontSize: '1.5rem',
                                cursor: 'pointer',
                                color: 'var(--wl-btn-background-primary-collor)',
                              }}
                              onClick={() => {
                                navigate(`/contacts/details/${data.contact.id}`)
                              }}
                            ></i>
                          </div>

                          {data.contact.cpf ? (
                            <>
                              {`CPF: ${data.contact.cpf}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {data.contact.createdAt ? (
                            <>
                              {`Criado em ${new Date(data.contact.createdAt).toLocaleString()}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}

                          {currentUser?.customerAccount?.id &&
                            data.contact?.integrationId &&
                            (1 == currentUser?.customerAccount?.id ||
                              1 == currentUser?.customerAccount?.reseller?.id) && (
                              <div
                                className='link-wl-custom-primary-collor'
                                style={{
                                  fontWeight: '500',
                                  paddingTop: '10px',
                                  paddingBottom: '5px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (window) {
                                    window.open(
                                      `https://app-spa.poli.digital/chat#${data.contact.integrationId}`,
                                      '_blank'
                                    )
                                  }
                                }}
                              >
                                <i
                                  className='fa-solid fa-arrow-up-right-from-square'
                                  style={{marginRight: '3px'}}
                                ></i>{' '}
                                Abrir chat
                              </div>
                            )}
                        </div>
                      </>
                    )}
                  </div>

                  <div className='tab-pane fade show' id='card_tab_pane_company' role='tabpanel'>
                    {data.company && (
                      <div className='entity-relation-card'>
                        <div className='d-flex justify-content-between'>
                          <h5>{data.company?.name}</h5>
                          <i
                            className='fa-solid fa-arrow-up-right-from-square'
                            style={{
                              fontSize: '1.5rem',
                              cursor: 'pointer',
                              color: 'var(--wl-btn-background-primary-collor)',
                            }}
                            onClick={() => {
                              navigate(`/companies/details/${data.company.id}`)
                            }}
                          ></i>
                        </div>
                        {data.company.corporateName ? (
                          <>
                            {`Razão Social: ${data.company.corporateName}`}
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                        {data.company.cnpj ? (
                          <>
                            {`CNPJ: ${data.company.cnpj}`}
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                        {data.company.createdAt ? (
                          <>
                            {`Criado em ${new Date(data.company.createdAt).toLocaleString()}`}
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </div>
                  {complementCustomForms?.map((form: any, index: number) => {
                    return (
                      <div
                        className={'tab-pane fade show'}
                        id={'card_tab_pane_complement' + form.id}
                        role='tabpanel'
                        key={index}
                      >
                        <>{form.name}</>
                        <CardsForm
                          isLoading={false}
                          customFormId={form.id}
                          card={data}
                          type={CUSTOM_FORM_TYPES.COMPLEMENT.id}
                          cardtype={cardFormModal.cardType}
                          funnelId={
                            cardFormModal.funnelId ? cardFormModal.funnelId : data.funnel?.id
                          }
                        />
                      </div>
                    )
                  })}
                </div>
              </Col>
              <Col style={{paddingLeft: '1.5rem'}}>
                <div
                  className=''
                  style={{fontSize: '12px', fontWeight: '700', marginBottom: '3px'}}
                >
                  Fase Atual{' '}
                  <div
                    className='badge fw-bolder'
                    style={{
                      fontSize: '12px',
                      fontWeight: '700',
                      marginLeft: '3px',
                      color: '#fff',
                      backgroundColor: data?.funnelStep?.color
                        ? data?.funnelStep?.color
                        : '#BBEA54',
                    }}
                  >
                    {data?.funnelStep?.title}
                  </div>
                </div>
                <hr style={{borderColor: '#e1e3ea', opacity: 1}}></hr>
                <DetailMenu card={data} />
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export {CardDetailModal}
