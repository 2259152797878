import {useQuery} from '@tanstack/react-query'
import debounce from 'debounce-promise'
import {Formik} from 'formik'
import {FC, useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {formatCustomFieldDbDataToInput} from '../../../../_metronic/helpers/custom-inputs/formatCustomFieldDbDataToInput'
import {formatCustomFieldInputDataToDb} from '../../../../_metronic/helpers/custom-inputs/formatCustomFieldInputDataToDb'
import {useAuth} from '../../../modules/auth'
import {ListLoading} from '../../../modules/view/ListLoading'
import {useFunnelDispatch} from '../../funnel/core/FunnelContext'
import {
  getCustomFields,
  getCustomFormById,
  getDefaultFields,
} from '../../settings/custom-forms/core/_requests'
import FieldTypeInput from '../../settings/custom-forms/manage-modal/CustomFormsFormComponents/FieldTypeInput'
import {Card} from '../core/_models'
import {
  createCard,
  getCardById,
  getFunnels,
  getLabels,
  getTicketCategories,
  getUsers,
  updateCard,
} from '../core/_requests'

type Props = {
  isLoading: boolean
  card?: Card
  cardtype?: number
  action?: string
  funnelId?: number
  addData?: any
  customFormId?: number
  onSave: any
}

const editDataSchema = Yup.object().shape({})

const CardsForm: FC<Props> = ({
  card,
  cardtype,
  isLoading,
  action,
  funnelId,
  addData,
  customFormId,
  onSave,
}) => {
  const {currentUser} = useAuth()
  const funnelDispatch = useFunnelDispatch()
  //console.log("customFormId", customFormId, "card",card?.updatedAt, card?.title, "custom", JSON.stringify(card?.customFields));

  const {
    isLoading: systemFormIsLoading,
    isError: systemFormIsError,
    data: systemFormData,
    error: systemFormError,
  } = useQuery({
    queryKey: ['cards', 'custom-forms', 'details', 'card', 'customform', customFormId],
    queryFn: async () => {
      let dataRes: any = null
      if (funnelId) {
        //Necessário estar na tela de funil para buscar o formulário de sistema do card
        if (customFormId) {
          dataRes = await getCustomFormById(customFormId)
        }

        if (dataRes && dataRes?.fields?.length >= 0) {
          let workFields: any = []
          dataRes?.fields?.forEach((e: any) => {
            if (e.customFieldId) {
              let options = e.customField?.options ? e.customField.options : []
              workFields.push({...e, name: 'custom_' + e.customFieldId, options: options})
            } else {
              workFields.push(e)
            }
          })

          dataRes.fields = workFields
        }
      }

      return dataRes
    },
    enabled: true,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: defaultSystemFieldsIsLoading,
    isError: defaultSystemFieldsIsError,
    data: defaultSystemFields,
    error: defaultSystemFieldsError,
  } = useQuery({
    queryKey: ['cards', 'custom-forms', 'default-fields', 'card', 'cardType', cardtype],
    queryFn: async () => {
      let dfFields = await getDefaultFields('card')
      let workDfFields: any = []
      dfFields.forEach((e: any) => {
        if (e.cardTypes && !e.cardTypes.includes(cardtype)) return
        workDfFields.push({...e, type: {key: e.type}})
      })
      return workDfFields
    },
    enabled: !systemFormData && !systemFormIsError,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: customFieldsIsLoading,
    isError: customFieldsIsError,
    data: customFields,
    error: customFieldsError,
  } = useQuery({
    queryKey: ['cards', 'custom-forms', 'custom-fields', 'card', 'cardType', cardtype],
    queryFn: async () => {
      let cfields = await getCustomFields('card', cardtype)
      let workCFields: any = []
      cfields.forEach((e: any) => {
        workCFields.push({...e, name: 'custom_' + e.id})
      })
      return workCFields
    },
    enabled: true,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const loadLabels = () => {
    let labels: Array<any> = []
    if (!card) return labels
    if (card.labels == undefined) return labels
    for (let i = 0; i < card.labels?.length; i++) {
      labels.push({label: card.labels[i].name, value: card.labels[i].id})
    }
    return labels
  }

  const loadCustomFields = (entityCustomFields: any, customFieldsCheck: any) => {
    let fields: any = {}
    if (!entityCustomFields) return fields

    for (let i = 0; i < entityCustomFields.length; i++) {
      let useCField = entityCustomFields[i]

      let formatReturn = formatCustomFieldDbDataToInput(useCField, customFieldsCheck)

      fields['custom_' + useCField.id] = formatReturn
    }

    return fields
  }

  const [dataForEdit, setDataForEdit] = useState<any>(null)

  const labelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let labels = await getLabels('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (labels && labels != undefined) {
        for (let i = 0; i < labels.length; i++) {
          list.push({label: labels[i].name, value: labels[i].id})
        }
      }
      resolve(list)
    })
  const debounceLabelsOptions = debounce(labelsOptions, 500)

  const responsiblesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (resps && resps.data != undefined) {
        for (let i = 0; i < resps?.data?.length; i++) {
          list.push({label: resps.data[i].name, value: resps.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceResponsiblesOptions = debounce(responsiblesOptions, 500)

  const peopleOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let peop = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (peop && peop.data != undefined) {
        for (let i = 0; i < peop?.data?.length; i++) {
          list.push({label: peop.data[i].name, value: peop.data[i].id})
        }
      }
      resolve(list)
    })
  const debouncePeopleOptions = debounce(peopleOptions, 500)

  const ticketCategoriesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let tkc = await getTicketCategories(
        'page=1&perPage=200&search=' + encodeURIComponent(inputValue)
      )
      if (tkc && tkc.data != undefined) {
        for (let i = 0; i < tkc?.data?.length; i++) {
          list.push({label: tkc.data[i].name, value: tkc.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceTicketCategoriesOptions = debounce(ticketCategoriesOptions, 500)

  const funnelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let funnels = await getFunnels(
        `page=1&perPage=2000&type=${cardtype}&search=` + encodeURIComponent(inputValue)
      )

      if (funnels && funnels != undefined) {
        for (let i = 0; i < funnels?.length; i++) {
          list.push({label: funnels[i].name, value: funnels[i].id})
        }
      }

      resolve(list)
    })
  const debounceFunnelsOptions = debounce(funnelsOptions, 500)

  const mountSelectLoadOptions = (field: any) => {
    switch (field.name) {
      case 'labelIds':
        return debounceLabelsOptions
      case 'responsibleId':
        return debounceResponsiblesOptions
      case 'funnelId':
        return debounceFunnelsOptions
      case 'ticketCategoryId':
        return debounceTicketCategoriesOptions
    }

    switch (field.type?.key) {
      case 'person':
        return debouncePeopleOptions
      default:
        return undefined
    }
  }

  const needDisableField = (field: any) => {
    if (action == 'edit' && ['funnelId', 'funnelStepId', 'responsibleId'].includes(field.name)) {
      return true
    }
    // if (action == 'duplicate' && ['funnelId'].includes(field.name)) {
    //   return true
    // }
    if (action == 'add' && 'contactId' == field.name && addData?.contactId) {
      return true
    }

    return false
  }

  useEffect(() => {
    if (customFields === undefined) return undefined //Aguardar para ver se tem campos customizados
    if (dataForEdit) return undefined

    // console.log("useeffect card.title", card?.title, "custom", JSON.stringify(card?.customFields));

    let dataState = {
      ...card,
      companyId: card?.company ? {label: card.company.name, value: card.company.id} : undefined,
      responsibleId: card?.responsible
        ? {label: card.responsible.name, value: card.responsible.id}
        : undefined,
      contactId: card?.contact ? {label: card.contact.name, value: card.contact.id} : undefined,
      funnelId: card?.funnel ? {label: card.funnel.name, value: card.funnel.id} : undefined,
      funnelStepId: card?.funnelStep
        ? {label: card.funnelStep.title, value: card.funnelStep.id}
        : undefined,
      ticketCategoryId: card?.ticketCategory
        ? {label: card.ticketCategory.name, value: card.ticketCategory.id}
        : undefined,
      labelIds: loadLabels(),
      ...loadCustomFields(card?.customFields, customFields),
    }

    if (action == 'add' || action == 'duplicate') {
      if (!dataState?.responsibleId) {
        if (currentUser) {
          dataState.responsibleId = {label: currentUser?.name, value: currentUser?.id}
        }
      }
      if (!dataState?.funnelId) {
        if (addData?.funnelId) {
          dataState.funnelId = addData?.funnelId
        }
      }
      if (!dataState?.funnelStepId) {
        if (addData?.funnelStepId) {
          dataState.funnelStepId = addData?.funnelStepId
        }
      }

      if (!dataState?.contactId) {
        if (addData?.contactId) {
          dataState.contactId = addData?.contactId
        }
      }
    }

    setDataForEdit(dataState)
  }, [systemFormIsLoading, defaultSystemFieldsIsLoading, customFieldsIsLoading, card])

  if (systemFormIsLoading || defaultSystemFieldsIsLoading || customFieldsIsLoading || !dataForEdit)
    return <ListLoading />

  //Aguardar preencher os campos com o valores padrão
  if (!dataForEdit.responsibleId && (action == 'add' || action == 'duplicate'))
    return <ListLoading />

  if (systemFormIsError) return <>Ops.. Falha ao carregar formulário. Tente novamente.</>

  if (defaultSystemFieldsIsError) return <>Ops.. Falha ao carregar formulário. Tente novamente.</>

  return (
    <>
      <Formik
        initialValues={dataForEdit}
        validationSchema={editDataSchema}
        onSubmit={async (values, {setSubmitting, resetForm}) => {
          setSubmitting(true)
          //console.log('values', values)

          let auxValues: any = {...values}
          if (!auxValues.title)
            return Swal.fire({
              title: 'Atenção',
              text: 'Campo Título é Obrigatório.',
              icon: 'warning',
              confirmButtonText: 'OK',
            })
          if (!auxValues.funnelId)
            return Swal.fire({
              title: 'Atenção',
              text: 'Campo Funil é Obrigatório',
              icon: 'warning',
              confirmButtonText: 'OK',
            })
          if (!auxValues.funnelStepId)
            return Swal.fire({
              title: 'Atenção',
              text: 'Campo Etapa do Funil é Obrigatório',
              icon: 'warning',
              confirmButtonText: 'OK',
            })
          if (auxValues.id == null) delete auxValues.id

          if (action == 'duplicate') delete auxValues.id

          if (auxValues.companyId && auxValues.companyId.value) {
            auxValues.companyId = auxValues.companyId.value
          } else {
            auxValues.companyId = null
          }

          if (auxValues.contactId && auxValues.contactId.value) {
            auxValues.contactId = auxValues.contactId.value
          } else {
            auxValues.contactId = null
          }

          if (auxValues.ticketCategoryId && auxValues.ticketCategoryId.value) {
            auxValues.ticketCategoryId = auxValues.ticketCategoryId.value
          } else {
            auxValues.ticketCategoryId = null
          }

          if (auxValues.funnelId && auxValues.funnelId.value) {
            auxValues.funnelId = auxValues.funnelId.value
          }

          if (auxValues.responsibleId && auxValues.responsibleId.value) {
            auxValues.responsibleId = auxValues.responsibleId.value
          } else {
            auxValues.responsibleId = null
          }

          if (auxValues.funnelStepId && auxValues.funnelStepId.value) {
            auxValues.funnelStepId = auxValues.funnelStepId.value
          }

          if (auxValues.status != undefined) {
            auxValues.status = auxValues.status ? 1 : 0
          }

          if (auxValues.labelIds) {
            let auxLabels: Array<number> = []
            for (let i = 0; i < auxValues.labelIds.length; i++) {
              auxLabels.push(auxValues.labelIds[i].value)
            }
            auxValues.labelIds = auxLabels
          }

          let customFieldsValue = []
          let objKeys = Object.keys(auxValues)
          for (let i = 0; i < objKeys.length; i++) {
            if (objKeys[i].includes('custom_')) {
              let useField = auxValues[objKeys[i]]
              let idCustom = objKeys[i].replace('custom_', '')

              let valueCustom = formatCustomFieldInputDataToDb(
                {
                  id: idCustom,
                  value: useField,
                },
                customFields
              )

              if (valueCustom === undefined) {
                console.log('Falha ao formatar valor do campo customizado')
                continue
              }

              customFieldsValue.push({
                id: idCustom,
                value: valueCustom,
              })
            }
          }
          auxValues.customFields = customFieldsValue

          try {
            let opUpSert = null

            if (action == 'edit') {
              let updated = await updateCard(auxValues)

              if (updated?.funnel?.id) {
                //Já tem expansões
                opUpSert = updated
              } //Não tem expansões
              else {
                if (updated) opUpSert = await getCardById(updated.id)
              }

              if (funnelDispatch)
                funnelDispatch({
                  type: 'update-step-card',
                  data: opUpSert,
                })

              onSave(updated)
            } else if (action == 'add' || action == 'duplicate') {
              auxValues.type = cardtype
              let created = await createCard(auxValues)

              if (created?.funnel?.id) {
                //Já tem expansões
                opUpSert = created
              } //Não tem expansões
              else {
                if (created) opUpSert = await getCardById(created.id)
              }

              if (funnelDispatch)
                funnelDispatch({
                  type: 'add-step-card',
                  data: opUpSert,
                })

              onSave(created)
            }

            Swal.fire('Registro salvo com Sucesso!!', '', 'success')
          } catch (ex: any) {
            console.error(ex)

            let errorDetected = ''
            if (ex.response?.data?.key && ex.response?.data?.message) {
              errorDetected = ex.response?.data?.message
            } else if (ex.response?.data?.message && Array.isArray(ex.response?.data?.message)) {
              if (ex.response?.data?.message.includes('title should not be empty'))
                errorDetected = 'Campo Título é Obrigatório'
              else if (ex.response?.data?.message.includes('funnel should not be empty'))
                errorDetected = 'Campo Funil é Obrigatório'
              else if (ex.response?.data?.message.includes('funnelStep should not be empty'))
                errorDetected = 'Campo Etapa do Funil é Obrigatório'
            }

            Swal.fire({
              title: 'Opss..',
              text: errorDetected ? errorDetected : 'Houve um problema ao salvar.',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {(props) => (
          <form
            id='kt_modal_add_user_form'
            className='form'
            onSubmit={props.handleSubmit}
            noValidate
          >
            <div className='d-flex flex-column gap-8' id='kt_modal_add_user_scroll'>
              {systemFormData &&
                systemFormData.fields.map((field: any, index: number) => {
                  return (
                    <FieldTypeInput
                      key={index}
                      field={field}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[field.name]}
                      selectLoadOptions={mountSelectLoadOptions(field)}
                      setFieldValue={props.setFieldValue}
                      disabled={needDisableField(field)}
                    />
                  )
                })}

              {!systemFormData &&
                defaultSystemFields &&
                defaultSystemFields.map((field: any, index: number) => {
                  return (
                    <FieldTypeInput
                      key={index}
                      field={field}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[field.name]}
                      selectLoadOptions={mountSelectLoadOptions(field)}
                      setFieldValue={props.setFieldValue}
                      disabled={needDisableField(field)}
                    />
                  )
                })}

              {!systemFormData &&
                customFields &&
                customFields.map((field: any, index: number) => {
                  return (
                    <FieldTypeInput
                      key={index}
                      field={field}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[field.name]}
                      selectLoadOptions={mountSelectLoadOptions(field)}
                      setFieldValue={props.setFieldValue}
                    />
                  )
                })}
            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}
            <div className='text-center pt-15'>
              <button
                type='submit'
                className='btn btn-sm btn-wl-custom-primary-collor'
                data-kt-users-modal-action='submit'
                disabled={isLoading || props.isSubmitting || !props.isValid || !props.touched}
              >
                <span className='indicator-label'>Salvar</span>
                {(props.isSubmitting || isLoading) && (
                  <span className='indicator-progress'>
                    Por Favor Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
            {(props.isSubmitting || isLoading) && <ListLoading />}
          </form>
        )}
      </Formik>
    </>
  )
}

export {CardsForm}
